<template>
    <v-select
        hide-details
        style="max-width: 50px"
        v-model="$i18n.locale"
        :items="languages"
    ></v-select>
</template>

<script>
export default {
    name: "locale-changer",
    data() {
        return {
            languages: ["en", "es"],
        };
    },
};
</script>