var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    staticStyle: { "max-width": "50px" },
    attrs: { "hide-details": "", items: _vm.languages },
    model: {
      value: _vm.$i18n.locale,
      callback: function($$v) {
        _vm.$set(_vm.$i18n, "locale", $$v)
      },
      expression: "$i18n.locale"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }